import { Container, Heading, Page, Section } from '@troon/ui';
import { AccessProducts } from '../../../../components/access-products';
import { useGolfer } from '../_providers/golfer';

export default function AccessAssociateLanding() {
	const golfer = useGolfer();
	return (
		<Container size="small">
			<Page>
				<Section class="text-center">
					<Heading as="h1">
						Choose a plan for {golfer.firstName} {golfer.lastName}
					</Heading>
					<p>Select a Troon Access plan to continue.</p>
				</Section>

				<Section>
					<AccessProducts floating forUser={false} urlPrefix="/_/associate" />
				</Section>
			</Page>
		</Container>
	);
}
